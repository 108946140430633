:root {
	/* Primary */
	--light-100: #b3cfd7;
	--primary: #063c6d;
	--dark-600: #023768;
	--dark-700: #003566;
	--dark-800: #001d3d;
	--dark-900: #000814;
	/* General */
	--white: #e6eff2;
	--success: #39c298;
	--danger: #ff758f;
	/* --gray-900: #161618;
	--gray-800: #1d1c21;
	--gray-700: #242329;
	--gray-600: #3d3b45; */

	/* Font sizes */
	--fs-body: 18px;
	/* --fs-large: 20px; */
	/* Font family */
	--family-body: Karla, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	--family-primary: Poppins;
}

* {
	color: var(--white) !important;
	box-sizing: border-box;
}

/* Font Starts */
html,
body {
	font-family: var(--family-body);
	font-size: var(--fs-body);
	line-height: 1.5;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	transition: all 0.2s ease;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
	border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.4);
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(255, 255, 255, 0.7);
}

h1,
h2,
h3,
h4,
h5 {
	font-family: var(--family-primary);
}

a {
	color: var(--success) !important;
}

.text-center {
	text-align: center;
}

.ant-input,
.ant-form-large .ant-form-item-label > label,
.ant-menu,
.ant-table,
.ant-collapse,
.ant-btn,
.ant-tabs-tab,
.ant-tabs,
.ant-input-group-addon,
.ant-card,
.ant-tooltip,
.ant-dropdown,
.ant-picker-input > input {
	font-size: var(--fs-body);
}

/* Font Ends */

/* Background starts */

.ant-badge-status-processing,
.ant-alert-success {
	background-color: var(--success);
}

.ant-switch,
.ant-input[disabled] {
	background: var(--dark-900);
}
.ant-switch-checked,
.ant-btn,
.ant-dropdown-menu-item-selected {
	background: var(--primary);
}

.ant-menu-item-selected {
	background: var(--primary) !important;
}

.ant-btn:active,
.ant-btn:hover,
.ant-btn:focus,
.ant-dropdown-menu-item:hover,
.ant-input,
.ant-form-item-has-error
	:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
	:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-picker-panel-container {
	background: var(--dark-600);
}

.ant-pagination-item-active,
.ant-dropdown-menu,
.ant-collapse,
.ant-layout-sider-zero-width-trigger,
.ant-menu-dark.ant-menu-inline .ant-menu-item:hover,
.ant-picker,
.ant-picker-clear,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
	.ant-picker-cell-inner,
.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
	.ant-picker-cell-inner::after,
.ant-picker-date-panel
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
	.ant-picker-cell-inner::after {
	background: var(--dark-700);
}
.ant-pagination-item,
.ant-pagination-item-link,
.ant-select-selector,
.ant-select-dropdown,
.ant-input-group-addon {
	background: var(--dark-700) !important;
	border: none !important;
}

.ant-layout-sider,
.ant-menu,
.ant-table-thead > tr > th,
.ant-card,
.ant-table,
.ant-layout-header,
.ant-collapse-content,
.ant-modal-content,
.ant-modal-header,
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel
	> :not(.ant-picker-date-panel)
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
	> :not(.ant-picker-date-panel)
	.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
	.ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
	background: var(--dark-800);
}
.ant-menu,
.ant-select-item-option-selected,
.ant-select-item-option-active {
	background: var(--dark-800) !important;
}
/*  */
html,
body,
.ant-menu-item-selected,
.ant-layout,
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background: var(--dark-900);
}
.ant-table-cell-row-hover {
	background: var(--dark-900) !important;
}
/* Background ends */
/* Color starts */
.text-success {
	color: var(--success) !important;
}
/* Color ends */

/* Ant override start */
.ant-btn {
	height: auto;
	border-radius: 3px;
	padding: 7px 16px;
	border: none;
}
a.ant-btn {
	padding: 7px 16px !important;
}
.ant-btn {
	/* color: var(--white); */
	box-shadow: 0px 12px 17px 2px hsla(0, 0%, 0%, 0.14),
		0px 5px 22px 4px hsla(0, 0%, 0%, 0.12),
		0px 7px 8px -4px hsla(0, 0%, 0%, 0.2);
}
.ant-btn[disabled] span {
	color: var(--light-100) !important;
}
.ant-btn[disabled],
.ant-btn[disabled]:hover {
	background: var(--dark-700);
}
.ant-input {
	border-radius: 3px;
	padding: 7px 16px;
}
.ant-picker-input > input {
	background: none;
}
.ant-form-item-has-error
	:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
	box-shadow: 0 0 0 2px rgb(255 77 79 / 60%);
}
/* .ant-btn:hover {
	transform: translateY(-2px);
}
.ant-btn:active,.ant-btn:focus {
	transform: translateY(0);
} */
.ant-switch {
	width: 3rem;
	border-radius: 3px;
	box-shadow: 0 0 0 2px var(--white);
}
.ant-switch:hover,
.ant-switch:focus {
	box-shadow: 0 0 0 2px var(--white) !important;
}
.ant-switch-handle {
	top: 4px;
	left: 4px;
	width: 14px;
	height: 14px;
}
.ant-switch-handle::before {
	border-radius: 2px;
}
.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 14px - 4px);
}
.ant-table {
	overflow: auto;
}
/* Ant override ends */

.ant-form-large .ant-form-item-label > label {
	height: auto;
}

.ant-input::placeholder {
	color: var(--light-100);
}

.ant-input,
.ant-form-large .ant-form-item-label > label,
.ant-menu,
.ant-table,
.ant-collapse {
	border: none;
}

.ant-pagination-item-link {
	border: none !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	font-size: 16px !important;
	padding: 16px 32px !important;
}

.ant-menu-item > span > a {
	color: var(--white) !important;
}

.logo {
	object-fit: contain;
}

.ant-layout {
	min-height: 100vh;
}

.ant-card {
	border: none;
	border-radius: 4px;
}

#calendar {
	height: 50vh;
}

/* .ant-menu-inline > .ant-menu-item {
	height: 4rem;
	line-height: 4rem;
} */

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-card-head,
.ant-modal-header,
.ant-modal-footer,
.ant-picker {
	border-color: var(--dark-600);
}

.ant-card-head .ant-typography span {
	color: var(--light-100) !important;
}

.ant-table-cell {
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: 200px;
	overflow: hidden;
}

/* offset-x | offset-y | blur-radius | spread-radius | color */

.mobile-overlay {
	display: none;
}

.ant-form-item-explain-error {
	color: var(--danger) !important;
}

.ant-collapse-content {
	border-color: var(--dark-600);
}
.ant-collapse-item {
	border-color: var(--dark-600) !important;
}

.ant-layout-sider {
	z-index: 1;
	box-shadow: 0 0 70px 0 rgb(0 0 0 / 50%);
}

.ant-badge-status-dot {
	width: 16px;
	height: 16px;
}

.ant-badge-status-processing::after {
	border-color: var(--white);
	border-width: 4px;
}

.ant-layout-sider-zero-width-trigger {
	position: fixed;
	top: 60px;
	left: 200px;
	right: auto;
	transition: all 0.2s ease;
}
.ant-layout-sider-zero-width-trigger .anticon {
	display: none;
}
.ant-layout-sider-zero-width-trigger::after {
	content: 'X';
}

.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger {
	position: fixed;
	left: 0;
	right: auto;
}

.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger .anticon {
	/* content: '>'; */
	display: initial;
}
.ant-layout-sider-collapsed .ant-layout-sider-zero-width-trigger::after {
	display: none;
}

.ant-layout-content {
	margin: 5.5rem 1.5rem 3rem 13rem;
	overflow: hidden !important;
}

hr {
	margin: 2rem 0;
	border-color: var(--dark-600);
}

.rsm-svg {
	background: white;
}

.ant-tabs-top > .ant-tabs-nav::before {
	border-color: var(--dark-600);
}

.ant-tabs-ink-bar {
	background: var(--success);
}

code {
	background: var(--dark-900);
	padding: 4px;
	border-radius: 4px;
}

.dashboard-chart-container-wrapper {
	width: 100%;
	overflow: auto;
	/* height: 400px;
	min-width: 600px; */
}

.dashboard-chart-container {
	height: 400px;
	min-width: 500px;
}

.dashboard-chart-container canvas {
	height: 100%;
}

.ant-layout-header {
	padding: 0 1.5rem;
	position: fixed;
	z-index: 1;
	width: 100%;
	display: flex;
	align-items: center;
}

.sider-trigger {
	background: none;
	border: none;
	width: 24px;
	padding: 0 3rem 0 0.2rem;
}
.desktop-hidden {
	display: none;
}

.checkbox-group label {
	display: flex;
}

.ant-dropdown {
	max-height: 400px;
	overflow: auto;
}

@media (max-width: 990px) {
	h2.ant-typography {
		font-size: 1rem;
	}
	.dashboard-card-heading {
		font-size: 1rem !important;
	}
	.dashboard-card-value {
		font-size: 1.5rem !important;
	}
	.dashboard-chart-container {
		height: 300px;
	}
	.desktop-hidden {
		display: initial;
	}
	.mobile-hidden {
		display: none;
	}
	.ant-layout-header {
		padding: 0 1rem;
	}
	.ant-layout-content {
		margin: 6rem 1rem 3rem;
	}
	/* .ant-layout-sider {
		border-right: 1px solid var(--dark-600);
	} */
}
